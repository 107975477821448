// Polish
export default{
    form: {
        startSurveyButton: "Zacznij",
        nextButton: "Dalej",
        submitAnswerButton: "Wyślij odpowiedź",
        continueButton: "Kontynuuj",
        finishButton: "Zakończ",
        headerPercentageCompletion: 'ukończono',
        headerQuestionsCompleted: 'pytania',
        headerDefaultErrorMessage: 'Ups. Coś poszło nie tak.',
        brandingPoweredBy: 'Zasilane przez',
        brandingPromoWhySurvey: 'Dlaczego ankieta, skoro masz <b>{product}</b>?',
        brandingPromoGetStarted: 'Zacznij już dziś!',
        submissionPending: "Przesyłanie twojego voiceformu. Nie zamykaj okna.",
        submissionCanCloseWindow: "Twój voiceform został wysłany. Możesz zamknąć okno 👍",
        mediaAttachmentOverlayCloseButton: "Zamknij",
        expiredFormHeader: "Dziękujemy za wizytę!",
        expiredFormMessage: "Wygląda na to, że ankieta wygasła. Skontaktuj się z twórcą.",
        notPublishedFormHeader: "Formularz nieopublikowany!",
        notPublishedFormMessage: "Ankieta nie została opublikowana. Skontaktuj się z twórcą.",
        notPublishedFormRetryButton: "Spróbuj ponownie",
        errorFormHeader: "Ups!",
        errorFormMessage: "Coś poszło nie tak. Spróbuj później.",
        errorFormRetryButton: "Spróbuj ponownie",
        emptyFormHeader: "Dziękujemy za wizytę!",
        emptyFormMessage: "Ops! Formularz jest pusty. Skontaktuj się z twórcą, aby to naprawić. Dziękujemy za wyrozumiałość!",

        restoreFormHeader: "Masz formularz w toku!",
        restoreFormMessage: "Chcesz kontynuować od miejsca, gdzie skończyłeś?",
        restoreFormButton: "Kontynuuj formularz",
        restoreFormButtonNew: "Zacznij od nowa",

        audioPermissionHeader: "Chcesz odpowiedzieć głosem?",
        audioPermissionMessage: "Odpowiedź głosem jest szybsza i łatwiejsza.",
        audioPermissionAcceptButton: "Tak, chcę",
        audioPermissionDenyButton: "Nie, dziękuję",
        audioPermissionErrorHeader: "Mikrofon niedostępny",
        audioPermissionErrorMessage: "Sprawdź uprawnienia przeglądarki do mikrofonu.",
        audioPermissionErrorButton: "OK",
        audioRecorderFailedUpload: "Nie udało się przesłać nagrania",
        silenceDetectorError: "Nie słychać cię. Sprawdź ustawienia mikrofonu.",
        audioRecorderButtonRecord: "Nagraj",
        audioRecorderButtonRecordMore: "Nagraj więcej",
        voiceResponseSelectionMessage: "Wybierz sposób odpowiedzi...",
        voiceResponseSelectionOrDivider: "lub",
        voiceResponseTextInputPlaceholder: "Wpisz odpowiedź",
        voiceResponseTextInputCharacterCounterMinMessage: "Wprowadź co najmniej {min} znaków",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} znaków (minimum {min})",
        voiceResponseTextInputCharacterValidationMessage: "{count} znaków (minimum {min})",

        // checkbox
        checkboxValidationTooFew: "Wybierz co najmniej {min} opcję",
        checkboxValidationTooMany: "Wybierz maksymalnie {max} opcje",
        checkboxNoneOfTheAboveOption: "Żadna z powyższych",
        checkboxInvalidAnswer: "Nieprawidłowa odpowiedź.",

        // datepicker
        datePickerPlaceholder: "Wybierz datę",

        // dropdown
        // email
        emailLabel: "E-mail",
        emailInvalid: "Nieprawidłowy e-mail.",
        // file-upload
        fileUploadPluginNameCamera: "Kamera",
        fileUploadPluginNameCameraVideo: "Nagraj wideo",
        fileUploadPluginScreenCast: "Nagrywanie ekranu",
        fileUploadVideoImportFilesDefault: 'Nagraj lub prześlij wideo:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Wybierz opcję nagrywania wideo:',
        fileUploadVideoImportFilesNoCamera: 'Naciśnij, aby nagrać ekran',
        fileUploadVideoImportFilesNoScreenCast: 'Naciśnij, aby nagrać wideo',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Brak opcji nagrywania',

        // matrix
        matrixValidationMessage: "Odpowiedź prawidłowa.",
        matrixRow: "Wiersz",
        matrixColumn: "Kolumna",
        matrixRowRequired: "Wymagany wiersz {rowTitle}.",
        matrixRadioGroup: "Grupa radiowa",
        matrixCheckboxGroup: "Grupa pól wyboru",
        matrixGroupRequired: "{type} {groupTitle} dla wiersza {rowTitle} wymagane.",
        matrixColumnRequired: "Kolumna {columnTitle} dla wiersza {rowTitle} wymagana.",
        matrixColumnInvalid: "Kolumna {columnTitle} dla wiersza {rowTitle} nieprawidłowa.",
        matrixRequired: "Wymagane.",
        matrixNumericMustBeNumber: "Musi być liczbą.",
        matrixNumericMustBeGreaterThenMin: "Musi być większe niż {min}.",
        matrixNumericMustBeLessThenMax: "Musi być mniejsze niż {max}.",
        matrixNumericMustBeInteger: "Musi być liczbą całkowitą.",
        matrixNumericInvalidNumber: "Nieprawidłowa liczba.",

        // name
        nameLabel: "Imię",
        nameInvalid: "Podaj imię i nazwisko.",

        // nps
        npsNotLikely: "Mało prawdopodobne",
        npsExtremelyLikely: "Bardzo prawdopodobne",

        // numeric input
        numericInputRequired: "Wymagane.",
        numericInputMustBeNumber: "Musi być liczbą.",
        numericInputMustBeGreaterThenMin: "Musi być większe niż {min}.",
        numericInputMustBeLessThenMax: "Musi być mniejsze niż {max}.",
        numericInputMustBeInteger: "Musi być liczbą całkowitą.",
        numericInputInvalidNumber: "Nieprawidłowa liczba.",
        numericInputPlaceholder: "Wpisz liczbę",

        // phone
        phoneInvalid: "Nieprawidłowy numer telefonu.",
        phoneCountrySelectorLabel: 'Kod kraju',
        phoneCountrySelectorError: 'Wybierz kraj',
        phoneNumberLabel: 'Numer telefonu',
        phoneExample: 'Przykład:',

        // boolean
        booleanYesLabel: "Tak",
        booleanNoLabel: "Nie",

        //questionStep
        questionStepAudioQuestionLabel: "Pytanie audio",

        // errors
        invalidPhoneNumber: "{phone} to nieprawidłowy numer telefonu.",
        invalidEmail: "{email} to nieprawidłowy adres e-mail.",
        questionIsRequired: "Pytanie jest wymagane.",
        answerIsNotValid: "Odpowiedź nieprawidłowa.",
        unfinishedProbingDialogError: "Dokończ dialog.",
        cannotResumePartialResponse: "Nie można wznowić częściowej odpowiedzi.",
        failedToSubmitForm: "Nie udało się przesłać formularza. Sprawdź połączenie i spróbuj ponownie."
    }
}
